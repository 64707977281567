/* html {
  max-width: 500px;
  margin: 0 auto;
} */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; 
  border-radius: 10px; */
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    /* background: gray; */
    border-radius: 5px;
    background-image: linear-gradient(120deg, gray, #ddd);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: #b30000;  */
  }
}
